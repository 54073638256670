import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";
import axios from 'axios';
import React, { useState } from 'react';

import { trackPromise } from 'react-promise-tracker';
import RectangleLogo from "../assets/images/logos/rectangle-large-header.png";

const LoginForm = ({sendTokenBack}) => {
  const [client_id, setclient_id] = useState('')
  const [client_secret, setclient_secret] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [controlAPIURL, setControlAPIURL] = useState(null);

  if (controlAPIURL == null){
    if (process.env.NODE_ENV === "development") {
        setControlAPIURL("http://localhost:5002/")
      }
      else
      {
        setControlAPIURL("https://allotrac-log-viewer-api.allotrac.com.au/")
      }
    }

  const getToken = (e) => {
    e.preventDefault();
    const params = new URLSearchParams()
    params.append('client_id',client_id);
    params.append('client_secret',client_secret);
    params.append('grant_type','client_credentials');
    trackPromise(axios.post(controlAPIURL + 'oauth/token', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

      .then(response => { //This is an arrow function
        
        sendTokenBack(response.data.access_token)
        // return setAlertMessage(
        //   <Card>
        //     <CardBody>
        //       <Alert style={{margin: "0 0 0 0"}} color="success">
        //           Login Succeded! Redirecting to dashboard.
        //       </Alert>
        //     </CardBody>
        //   </Card>
        //   )
        })
      .catch((error) => {
        console.log(error);
        return setAlertMessage(
        <Card>
          <CardBody>
            <Alert style={{margin: "0 0 0 0"}} color="danger">
                Login Failed!
            </Alert>
          </CardBody>
        </Card>
        )
      })
  
    )}

  

  return (
    <Row style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
      <Col sm="5" lg="5" xl="4" xxl="3">

        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
          <center><img alt="Logo" style={{width:"200px"}} src={RectangleLogo} /></center>
          </CardTitle>
          <CardBody>
            <Form onSubmit={getToken}>
              <FormGroup>
                <Label for="exampleEmail">Username</Label>
                <Input
                  id="username"
                  name="username"
                  placeholder=""
                  type="text"
                  onChange={event => setclient_id(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Password</Label>
                <Input
                  id="examplePassword"
                  name="password"
                  placeholder=""
                  type="password"
                  onChange={event => setclient_secret(event.target.value)}
                />
                 
              </FormGroup>

              <Button style={{color:"#FFF", backgroundColor:"#00263A"}}>Submit</Button>
             
            </Form>
            
          </CardBody>
        </Card>
        {alertMessage}
      </Col>
    </Row>
  );
};

export default LoginForm;
