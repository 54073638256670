import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import React, { useState, useMemo, useEffect } from "react";
import LoginForm from "./views/LoginForm";
import Cookies from "universal-cookie";
// import { readContactApiAllotracContactPhpGet } from "./api/endpoints/dispatchBackend";
import { useDispatch, useSelector } from "react-redux";

const App = () => {
  const subdomain = useSelector(state => state.allotracDispatch.subdomain);
  const CORSAnywhereURL = "https://data.optimize.allotr.ac:8080/";
  let opts = useMemo(() => {
    console.log("Changing server opts, new subdomain");
    console.log(subdomain);

    return {
      url: CORSAnywhereURL + "https://" + subdomain + ".allotrac.com.au"
    };
  }, [subdomain]);

  const cookies = new Cookies();
  const [token, setToken] = useState(null);
  console.log("App Subdomain is: ", subdomain);
  const sendTokenBack = token => {
    setToken(token);
    cookies.set("token", token, { sameSite: "strict" });
  };
  const cookieToken = cookies.get("token");

  if (token == null) {
    if (typeof cookieToken !== "undefined" && cookieToken != null) {
      setToken(cookieToken);
    }
  }

  console.log(opts);
  const routing = useRoutes(Themeroutes(token));
  if (token != null) {
    return (
      <div>
        {(subdomain && <div></div>) || <div className="dark">{routing}</div>}
      </div>
    );
  } else {
    return <LoginForm sendTokenBack={sendTokenBack} />;
  }
};

export default App;
